import { config } from "../config";
const { STREAM_BASE_URL, RTMP_PORT, WEBRTC_PORT } = config;

/**
 * A fast and simple 53-bit string hash function with decent collision resistance.
 * https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js
 * @param {String} str
 * @param {Number} seed - 0 by default
 * @returns {String} 53-bit string
 */
export function hash(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for(let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

/**
 * @param {String} protocol - Default: `webrtc`, either `webrtc` or `rtmp`
 * @param {String} type - Default = `play`, either `play` or `push`, `play`: pull from server, `push`: push to server. 
 * `push` and `play` are the same for RTMP
 * @param {String} baseUrl - The hostname for your media server, eg. longanvisionlive.one 
 * @param {String} appName - The app name for param `?app=`, eg. `?app=123456`, appName should be the hash value from
 * user's company name
 * @param {String} streamName - The stream name for param `?stream=`, eg. `?stream=fvs`
 * @returns {String} - A full url for pushing or playing ZlMediaKit stream, 
 * eg. https://longanvisionlive.one:8443/index/api/webrtc?app=company-name-hash-string&stream=fvs1&type=play or
 * rtmp://longanvisionlive.one:1936/company-name-hash-string/fvs1
 */
export function generateFullStreamUrl(protocol = "webrtc", type = "play", baseUrl, port, appName, streamName) {
  let fullStreamUrl;
  switch (protocol) {
    case "rtmp":
      fullStreamUrl = `rtmp://${baseUrl}:${port}/${appName}/${streamName}`;
      break;
    case "webrtc":
      fullStreamUrl = `https://${baseUrl}:${port}/index/api/webrtc?app=${appName}&stream=${streamName}&type=${type}`;
      break;
    default:
      throw new Error("Invalid Protocol: " + protocol);
  }
  return fullStreamUrl;
}

/**
 * @type {Function}
 * @param {String} appName - The app name for param `?app=`, eg. `?app=123456`, appName should be the hash value from
 * user's company name
 * @param {String} streamName - The stream name for param `?stream=`, eg. `?stream=fvs`
 * @returns {String} A full url source for playing WebRTC stream.
 * eg. https://longanvisionlive.one:8443/index/api/webrtc?app=company-name-hash-string&stream=fvs4&type=play
 */
export const webrtcPlayUrl = generateFullStreamUrl.bind(null, "webrtc", "play", STREAM_BASE_URL, WEBRTC_PORT);

/**
 * @type {Function}
 * @param {String} appName - The app name for param `?app=`, eg. `?app=123456`, appName should be the hash value from
 * user's company name
 * @param {String} streamName - The stream name for param `?stream=`, eg. `?stream=fvs`
 * @returns {String} A full url destinaton for pushing RTMP stream.
 * eg. rtmp://longanvisionlive.one:1936/898022966435914/fvs1
 */
export const rtmpPushUrl = generateFullStreamUrl.bind(null, "rtmp", "push", STREAM_BASE_URL, RTMP_PORT);