import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { hash } from '../../utils';
import { logger } from '../../logger';

export const Context = createContext();

/**
 * React component that provides authentication state management.
 * @function AuthContext
 * @param {Object} props.children - Child components to be rendered.
 */
export const AuthContext = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  useEffect(() => {
    const unsubscribeAuthObserver = onAuthStateChanged(auth, async (currentUser) => {
      // <Proteced /> starts loading
      setLoading(true);

      if (currentUser) {
        // User log in
        setIsAuth(true);
        
        /**
         * The uid is stored in browser storage (be default, IndexedDB, check 
         * Firebase Auth persistent documentation: 
         * https://firebase.google.com/docs/auth/web/auth-state-persistence),
         * we use this as the key for session storage.
         * The userData is initally empty on page refresh or new page request,
         * so we check for session storage, if it doesn't exist, we fetch the
         * data from firebase and set it in session storage. This makes sure
         * that on each refresh and new page request, user's global state will
         * be restored.
         */
        const userLog = currentUser.email ? currentUser.email : currentUser.toJSON();
        logger.log(`Current user onAuthStateChanged: ${userLog}`);
        const uid = currentUser.uid;
        const sessionUserData = sessionStorage.getItem(uid);
        let userData;
        if (sessionUserData) {
          logger.log(`session data for ${userLog} was found`);
          userData = JSON.parse(sessionUserData);
        } else {
          logger.log(`session data for ${userLog} was not found, fetching from database...`);
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            logger.log(`User data retrieved from databse, setting session data for user ${userLog}`);
            userData = userDocSnap.data();
          } else {
            logger.log(`No document matched for ${userLog}`);
          }
        }
        
        /**
         * The appName is the group id for a set of streams, and will be
         * used for generating stream urls in child components.
         * Different company should have their own exlusive stream group.
         * That's why we are hashing user's company name as the unique id.
         */
        userData["appName"] = hash(userData.company);
        logger.log(`appName has been set to ${userData.appName}`);
        sessionStorage.setItem(uid, JSON.stringify(userData));
        setUser(userData);
        
        /**
         * The variable `lastPath` is set by <Protected /> on loading
         * and this will redirect user back to where they come from.
         * eg. User refresh on /profile, <Protected /> starts loading
         * and set `lastPath` to "/profile", onAuthStateChanged restores
         * user data and redirect user back to "/profile"
         */
        const lastPath = sessionStorage.getItem('lastPath') || '/home';
        logger.log(`AuthContext got lastPath: ${lastPath}`);
        navigate(lastPath, { replace: true });
      } else {
        // User log out
        setIsAuth(false);
        setUser(null);
        sessionStorage.clear();
      }
      // <Protected /> redirect to /signin (if not authenticated) 
      // or loads main components (if authenticated)
      setLoading(false);
    });
    return () => {
      unsubscribeAuthObserver();
    }
  // eslint-disable-next-line
  }, []);

  /**
   * Context object for authentication state management.
   * @typedef {Object}
   * @property {Object} user - Data of the current authenticated user
   * @property {Function} setUser - A function to set the current authenticated user's data
   * @property {Boolean} isAuth - A flag indicating whether current user is logged in,
   * used in <Protected />, which only renders sub-component when isAuth === true
   * @property {Boolean} loading - A flag indicating whether <Procted /> should render
   * the loading page when user data is still being restored 
   */
  const values = {isAuth, user, setUser, loading};

  return (
    <Context.Provider value={values}>
      {children}
    </Context.Provider>
  )
}
