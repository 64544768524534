import React from 'react'
import './NotFound.scss'
import { Link } from 'react-router-dom'

/**
 * Renders a page with an error message and a link to go back to the home page.
 * @returns {JSX.Element} The NotFound component
 */
const NotFound = () => {
  return (
    <div className='notfound-container'>
        <h1>Error 404</h1>
        <h2>Page not found</h2>
        <Link to='/home'>Go back to home page</Link>
    </div>
  )
}

export default NotFound