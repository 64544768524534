import React, { useContext } from 'react';
import { db } from '../firebase';
import { collection, query, getDocs,where} from "@firebase/firestore";
import { Context } from '../components/auth/AuthContext';
import VideoPlayer from '../components/video/VideoPlayer';
import './Recordings.css';

/**
 * Renders a page that displays recordings for the current user's company.
 * @returns {JSX.Element} The Recordings page component.
 */
export default function Recordings() {
    const [recordings, setRecordings] = React.useState([]);
    const { user } = useContext(Context);
    const my_company = user?.company;

    React.useEffect(() => {
        const fetchVidData = async () => {
            if (my_company) {
                const q = query(collection(db, "videos"), where("company", "==", my_company));
                const querySnapshot = await getDocs(q);
                const tempDocs = [];
                querySnapshot.forEach((doc) => {
                    tempDocs.push({ ...doc.data(), id: doc.id });
                });
                setRecordings(tempDocs);
            }
        };
        fetchVidData();
    }, [my_company]);

    /**
     * Groups the recordings by date.
     * @returns {Object} An object with dates as keys and arrays of recordings as values.
     */
    const groupRecordingsByDate = () => {
        const groupedRecordings = {};

        recordings.forEach((recording) => {
            const date = recording.time_created.toDate().toDateString();

            if (!groupedRecordings[date]) {
                groupedRecordings[date] = [];
            }

            groupedRecordings[date].push(recording);
        });

        return groupedRecordings;
    };

    /**
     * Renders the recordings grouped by date.
     * @returns {JSX.Element} The recordings grouped by date.
     */
    const renderRecordings = () => {
        if (recordings.length === 0) {
            return <p>No recordings available for {my_company}.</p>;
        }
        const groupedRecordings = groupRecordingsByDate();

        return Object.keys(groupedRecordings).map((date) => (
            <div className='RecordingGroup' key={date}>
                <h4 className='RecordingDate'>{date}</h4>
                <div className='RecordingList'>
                    {groupedRecordings[date].map((recording) => (
                        <VideoPlayer key={recording.id} url={recording.url} />
                    ))}
                </div>
            </div>
        ));
    };

    return (
        <div className='Recordings'>
            <h3 className='PageTitle'>Recordings of {my_company}</h3>
            <div className='RecordingGroups'>
                {renderRecordings()}
            </div>
        </div>
    );
}
