import React from 'react';
import AccountMenu from './AccountMenu';
import Stack from '@mui/material/Stack';
// import RecordingsButton from './RecordingsButton';
// import Help from './Help';
// import Notification from './Notification';

/**
 * A component that renders a dropdown menu using subcomponents.
 * @param {Object} props - The component props.
 * @param {number} props.width - The width of the dropdown menu.
 * @param {number} props.height - The height of the dropdown menu.
 * @returns {JSX.Element} - The JSX element representing the dropdown menu.
 */
const DropDown = ({ width, height }) => {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ height: 50 }}>
      {/* <RecordingsButton width={width} height={height} /> */}
      {/* <Help width={width} height={height} /> */}
      {/* <Notification width={width} height={height} /> */}
      <AccountMenu width={width} height={height} />
    </Stack>
  )
}

export default DropDown