import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBzwQDk5jwQjVmE8FU_TOpJ1M9Cqjsdcrw",
  authDomain: "lucky-science-370921.firebaseapp.com",
  projectId: "lucky-science-370921",
  storageBucket: "lucky-science-370921.appspot.com",
  messagingSenderId: "419127661643",
  appId: "1:419127661643:web:a9a7aad1aaa3beef9fe9ce"
};

const app = initializeApp(firebaseConfig);

/** 
 * Initialize Firebase Authentication and get a reference to the service
 */
export const auth = getAuth(app);

/**
 * The Firestore database instance.
 */
export const db = getFirestore(app);
