import React, { useState, useContext } from 'react';
import { collection, addDoc,Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import './SendATicket.css';
import { Context } from '../components/auth/AuthContext';
import { logger } from '../logger';

/**
 * Renders a form for sending a ticket with a title and description.
 * @returns {JSX.Element} The SendATicket component.
 */
function SendATicket() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [message, setMessage] = useState(''); // For feedback after sending

    const { user } = useContext(Context);

    /**
     * Handles form submission by adding a new ticket to the database.
     * @param {Event} e - The form submission event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!title || !description) {
            setMessage("Please fill in both fields");
            return;
        }

        try {
            await addDoc(collection(db, 'tickets'), {
                title: title,
                name: user.fname + " " + user.lname,
                uid: user.uid,
                email: user.email,
                description: description,
                createdAt: Timestamp.fromDate(new Date()),
                
            });
            setMessage('Ticket successfully sent!');
            setTitle('');
            setDescription('');
        } catch (error) {
            logger.error("Error sending ticket: ", error);
            alert("Error sending ticket: ", error);
            setMessage('Error sending ticket.');
        }
    };

    return (
        <div className="send-ticket-container">
            <h2>Send a Ticket</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div>
                    <label>Description:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </div>
                <button type="submit">Send Ticket</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default SendATicket;
