/**
 * configuration for the entire react app
 */
export const config = {
    "STREAM_BASE_URL": "demo.longanvision.com",
    "STREAM_NAME": "fvs",
    "RTMP_PORT": "1936",
    "WEBRTC_PORT": "8443",
};

/** 
 * ===================
 * The App environment
 * ===================
 * Unless explicitly specified REACT_APP_APP_ENV, APP_ENV defaults to development
 * When pushing to production, REACT_APP_APP_ENV='production' npm start
 *                          or REACT_APP_APP_ENV='production' npm build
 **/
export const APP_ENV = process.env.REACT_APP_APP_ENV === 'production' ? 'production' : 'development';

export const LOG_LEVEL = APP_ENV === 'production' ? 'warn' : 'log';