import React, { useState } from 'react';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth"
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { collection, getDocs, query, where } from "firebase/firestore";
import { deleteUser } from "firebase/auth";
import { logger } from '../../logger';

import "./Signup.css"

/**
 * Component for user sign up.
 * @returns {JSX.Element} Sign up form.
 */
const SignUp = () => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fname, setFirstName] = useState('');
  const [lname, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState('');

  /**
   * Check if the user's record already exists in Firestore. If it doesn't exist, create it.
   * If error occurs, don't create the user record.
   * @param {Event} e - The submit event.
   */
  const handleSignUp = async (e) => {
    e.preventDefault();

    let userCredential;
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      logger.log("Error signing up user: Email already exists");
      alert("Error signing up user: Email already exists");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          uid: user.uid,
          email: email,
          fname: fname,
          lname: lname,
          role: role,
          company: company,
          createdAt: new Date(),
        });
        alert("User successfully created.");
      } 
    } catch (error) {
      alert("Error signing up user: " + error.message);

      // Remove the auth user if creating the Firestore document fails.
      if (userCredential && userCredential.user) {
        await deleteUser(userCredential.user);
      }

    }
  };

  
  return (
    <div className="sign-up-container">
     
      <h1 className='signupTitle'>Sign Up for a New User</h1>
      <form onSubmit={handleSignUp}>
        <h3 className='regularText'>Email</h3>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <h3>Password</h3>
        <input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <h3>First name</h3>
        <input
          type="text"
          placeholder="Enter your first name"
          value={fname}
          onChange={(e) => setFirstName(e.target.value)}
        ></input>
        <h3>Last name</h3>
        <input
          type="text"
          placeholder="Enter your Last name"
          value={lname}
          onChange={(e) => setLastName(e.target.value)}
        ></input>
        <h3>Role</h3>
        <input
          type="text"
          placeholder="Enter your role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        ></input>
        <h3>Company</h3>
        <input  
          type="text"
          placeholder="Enter your company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        ></input>
         <button className='signUpBTN' type='submit'><span>Sign Up</span></button>
      </form>
    </div>
  );

};

export default SignUp;
