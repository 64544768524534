import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/auth/AuthContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { logger } from "../logger";
import "./ViewTickets.css";

/**
 * Renders a page that displays all tickets in the database if the user is an admin.
 * @returns {JSX.Element} The ViewTickets component.
 */
function ViewTickets() {
    const { user } = useContext(Context);
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        const fetchTickets = async () => {
            if (user.role === "admin") {
                try {
                    const querySnapshot = await getDocs(collection(db, "tickets"));
                    let fetchedTickets = [];
                    querySnapshot.forEach((doc) => {
                        fetchedTickets.push(doc.data());
                    });
                    setTickets(fetchedTickets);
                } catch (e) {
                    logger.warn("Error fetching tickets: ", e);
                }
            }
        };

        fetchTickets();
    }, [user.role]);

    return (
        <div>
            <h3>View Tickets</h3>
            {tickets.map((ticket, index) => (
                <div key={index} className="ticket-container">
                    <div className="ticket-title"> Title:{ticket.title}</div>
                    <div className="ticket-description">{ticket.description}</div>
                    <div className="ticket-from">From:{ticket.name}</div>
                    <div className="ticket-email">Email:{ticket.email}</div>
                    <div className="ticket-date">Date: {ticket.createdAt.toDate().toLocaleDateString()}</div>
                </div>
            ))}
        </div>
    );
    
}

export default ViewTickets;

    




