import { LOG_LEVEL } from './config';

/**
 * Represents a no-operation logging function.
 * @type {LogFn}
 */
const NO_OP = (message, ...optionalParams) => {};

/**
 * Logger that outputs to the browser console.
 * @class
 */
class ConsoleLogger {
  /**
   * Creates an instance of ConsoleLogger.
   * @param {Object} [options] - Configuration options.
   * @param {'log'|'warn'|'error'} [options.level] - The level of logging.
   */
  constructor(options) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;

      return;
    }

    this.log = console.log.bind(console);
  }
}

/**
 * Default logger instance with configurable log level.
 * @type {ConsoleLogger}
 */
export const logger = new ConsoleLogger({ level: LOG_LEVEL });
