import React from 'react';
import { Stack }  from '@mui/material';
import DropDown from '../DropDown';
import LogoButton from './LogoButton';
import './Navtab.css'

/**
 * A component that renders a navigation tab with a logo button and a dropdown menu.
 * @returns {JSX.Element} The Navtab component.
 */
const Navtab = () => {
  return (
    <Stack className='navtab' direction="row" justifyContent="space-between" alignItems="center">
      <LogoButton />
      <DropDown width='30px' height='30px' />
    </Stack>
  )
}

export default Navtab