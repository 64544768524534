import './App.css';
import React from 'react';
import Signin from './components/auth/Signin';
import SignUp from './components/auth/Signup';
import Profile from './pages/Profile';
import Recordings from './pages/Recordings';
import SendATicket from './pages/SendATicket';
import ViewTickets from './pages/ViewTickets';
import Homepage from './pages/Homepage';
import NotFound from './pages/NotFound';
import { AuthContext } from './components/auth/AuthContext';
import { Protected } from './components/auth/Protected';
import Navtab from './components/common/Navtab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";

/**
 * The main component of the LonganVisionStreamSite application.
 * @returns {JSX.Element} The App component.
 */
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#F05742',
      },
      secondary: {
        main: '#42DBF0',
      },
    },
  });

  const MainsiteLayout = () => {
    return (
      <Protected>
        <Navtab />
        <Outlet />
      </Protected>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthContext>
          <Routes>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="signin" element={<Signin />} />
            <Route element={<MainsiteLayout />}>
              <Route path="home" element={<Homepage />} />
              <Route path="profile" element={<Profile />} />
              <Route path="recordings" element={<Recordings />} />
              <Route path="sendaticket" element={<SendATicket />} />
              <Route path="viewtickets" element={<ViewTickets />} />
              <Route path="signUp" element={<SignUp />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthContext>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
