import React from 'react';

const VideoPlayer = ({ url }) => {
  return (
    <div className="video-player">
      <video controls width="320" height="240">
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoPlayer;