import React, { useEffect, useRef } from 'react';
import { Menu, Item, useContextMenu } from 'react-contexify';
import * as ZLMRTCClient from '../../3rdpart/ZLMRTCClient.js/src/export.js';
import { logger } from '../../logger.js';
import 'react-contexify/ReactContexify.css';
import "./Player.css"

/**
 * A React component that renders an HLS video player with a context menu.
 * @param {Object} props - The component props.
 * @param {string} props.url - The URL of the HLS stream to play.
 * @param {string} props.menuId - The ID of the context menu.
 * @param {string} props.className - The CSS class name for the component.
 * @param {function} props.handleItemClick - The function to call when a context menu item is clicked.
 * @param {string} props.layout - The layout of the video player.
 * @returns {JSX.Element} - The HLS video player component.
 */
export default function Player({ url, menuId, className, handleItemClick, layout }) {

  const videoRef = useRef(null);

  useEffect(() => {

    let player = new ZLMRTCClient.Endpoint({
      element: videoRef.current,
      debug: false,
      zlmsdpUrl: url,
      useCamera: false,
      audioEnable: false,
      videoEnable: true,
      recvOnly: true,
    });

    player.on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR, function (e) {
      // ICE 协商出错
      logger.warn('ICE 协商出错');
    });

    player.on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS, function (e) {
      //获取到了远端流，可以播放
      logger.log('播放成功', e.streams);
    });

    player.on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED, function (e) {
      // offer anwser 交换失败
      logger.warn('offer anwser 交换失败', e);
      if (player) {
        player.close();
        player = null;
      }
    });

    player.on(ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE, function (state) {
      // RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
      logger.log('当前状态==>', state);
    });

    return () => {
      if (player) {
        player.close();
        player = null;
      };
    };
  }, [url]);

  const { show } = useContextMenu({
    id: menuId,
  });

  const handleContextMenu = (e) => {
    show({ event: e, props: { key: "value" } });
  }

  return (
    <div className={className}>
      <video
        ref={videoRef}
        controls
        autoPlay
        className="react-player"
        style={{ width: '100%', height: '100%' }}
        onContextMenu={handleContextMenu}
      />
      <Menu id={menuId}>
        {Array.from(Array(4).keys()).map((i) => (
          <Item onClick={handleItemClick} id={i + 1} key={i}>Stream from camera {i + 1}</Item>
        ))}
        {layout === "left-one-right-three" && <Item onClick={handleItemClick} id="5">Swap with main display</Item>}
      </Menu>
    </div>
  )
}