import React from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

/**
 * A button component that navigates to the home page when clicked.
 * @returns {JSX.Element} The HomeButton component.
 */
const HomeButton = () => {
    const navigate = useNavigate();
    return (
        <Button
            sx={{ position: 'absolute', top: '1rem', left: '1rem' }}
            variant='outlined'
            color='primary'
            startIcon={<ArrowBackIosNew />}
            onClick={() => navigate('/home')}>
            Home
        </Button>
    )
}

export default HomeButton