import { useState, useContext } from 'react';
import { Stack, Avatar, Menu, MenuItem, ListItemIcon, Divider, IconButton, Tooltip } from '@mui/material';
import { PersonAdd, Logout, Help as HelpIcon, Article as ArticleIcon } from '@mui/icons-material';
import { signOut, getAuth } from "firebase/auth";
import { Context } from '../../auth/AuthContext';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../logger';

const AccountMenu = ({ width, height }) => {

    const { user } = useContext(Context);
    const navigate = useNavigate();
    const auth = getAuth()

    const userSignOut = () => {
        signOut(auth).catch((error) => logger.log(error));
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
       
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        setAnchorEl(null);
        navigate('/profile');
    }

    const handleSignUpNewUser = () => {
        setAnchorEl(null);
    }


    return (
        <>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="large"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Stack>
                        <Avatar sx={{ width: width, height: height, bgcolor: 'white', color: 'black' }}></Avatar>
                    </Stack>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            bgcolor: 'background.paper',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleProfileClick}>
                    <Avatar /> {user?.fname + " " + user?.lname}
                </MenuItem>
                <Divider />
                {/*
                
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <ArticleIcon fontSize="medium" />
                    </ListItemIcon>
                    Send A Ticket
                </MenuItem>
                */}
                {user.role === "admin" && (
                <MenuItem onClick={handleSignUpNewUser}>
                    <ListItemIcon>
                        <PersonAdd fontSize="medium" />
                    </ListItemIcon>
                    <Link to='/signup' style={{color: 'black'}}>Sign Up New User</Link>
                </MenuItem>
                )} 
                {user.role === "admin" && (
                 <MenuItem onClick={handleClick}>
                    <ListItemIcon>
                        <ArticleIcon fontSize="medium" />
                    </ListItemIcon>
                    <Link to='/viewtickets' style={{color: 'black'}}>View All Tickets</Link>
                    
                </MenuItem>
                )} 
                  <MenuItem onClick={handleClick}>
                    <ListItemIcon>
                        <HelpIcon fontSize="medium" />
                    </ListItemIcon>
                    <Link to='/sendaticket' style={{color: 'black'}}>Send A Ticket</Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { userSignOut() }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );

}

export default AccountMenu;