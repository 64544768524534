import { Navigate, useLocation  } from "react-router-dom";
import { useContext } from "react";
import { Context } from "./AuthContext";
import Loading from "../Loading";
import { logger } from "../../logger";

/**
 * A component that protects routes from unauthorized access.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be rendered if the user is authenticated.
 * @returns {ReactNode} - The child components if the user is authenticated, otherwise a redirect to the sign-in page.
 */
export function Protected({children}){
    const { isAuth, loading } = useContext(Context);
    const location = useLocation();
    if (loading) {
        sessionStorage.setItem("lastPath", location.pathname);
        logger.log(`Set lastPath in session to ${location.pathname}`);
        return <Loading />;
    }
    if (!isAuth) {
        return <Navigate to="/signin" replace />;
    }
    return children;
}