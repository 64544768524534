import React from 'react'
import './LogoButton.scss'
import { ORANGE_LOGO_WHITE_TEXT as logo } from '../../../assets/images';
import { useNavigate } from "react-router-dom";

const LogoButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/home');
    }

    return (
        <button className='logo-btn' onClick={handleClick}>
            <img src={logo} alt='longan vision logo' />
        </button>
    )
}

export default LogoButton