import React from 'react'
import './ProfileTable.css'

/**
 * A table component that displays a list of items with a title.
 * list.slice(1) is used to remove the title from the list.
 * list[0] is used to get the title.
 * @param {Object[]} list - The list of items to display.
 * @param {string} list[].title - The title of the table.
 * @param {string} list[].name - The name of the item.
 * @param {string} list[].value - The value of the item.
 * @returns {JSX.Element} - The rendered table component.
 */
const ProfileTable = ({ list }) => {

  const contents = list.slice(1).map((item, index) => {
    return (
      <div className='profile-table-entry' key={index}>
        <h2>{`${item.name}:`}</h2>
        <p>{item.value}</p>
      </div>
    )
  })

  return (
    <div className='profile-table'>
        <h2 className='profile-table-title'>{list[0].title}</h2>
        {contents}
    </div>
  )
}

export default ProfileTable