import { useContext } from 'react';
import { Context } from '../components/auth/AuthContext';
import Videos from '../components/video/Videos';
import './Homepage.css';
// import Sidebar from './Sidebar';

/**
 * Renders the homepage content.
 * @function
 * @description 
 * @returns {JSX.Element} - Rendered component.
 */
function Homepage() {
  const { user } = useContext(Context);

  return (
    <div className='homepage-content'>
      {/*<Sidebar className='sidebar' deviceList={DEVICE_LIST} /> */}
      <Videos className='videos' appName={user.appName}/>
    </div>

  );
}

export default Homepage;
