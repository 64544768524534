import React, { useContext } from 'react';
import { rtmpPushUrl } from '../../utils';
import { config } from '../../config';
import { Context } from '../../components/auth/AuthContext';
import HomeButton from '../../components/common/HomeButton'; 
import ProfileTable from './ProfileTable';
import './index.scss';

/**
 * Renders the user's profile page using predefined data (prfileInfo and companyInfo)
 * @returns {JSX.Element} The user's profile page.
 */
const Profile = () => {
  const { user } = useContext(Context);
  const { fname, lname, email, role, company, appName } = user;
  const { STREAM_NAME } = config;

  const profileInfo = [
    { title: 'Profile Info' },
    { name: 'First Name', value: fname },
    { name: 'Last Name', value: lname },
    { name: 'Email', value: email }
  ];

  const companyInfo = [
    { title: 'Company Info' },
    { name: 'Role', value: role },
    { name: 'Company', value: company }
  ];

  const streamInfo = [
    { title: 'Stream Settings'},
    { name: 'FVS Stream Url', value: rtmpPushUrl(appName, STREAM_NAME + "1")}
  ];

  return (
    <div className='profile-page'>
      <HomeButton />
      {user ? (
        <div className="profile-container">
          <h1 className='profile-heading'>Your Profile</h1>
          <ProfileTable list={profileInfo} />
          <ProfileTable list={companyInfo} />
          <ProfileTable list={streamInfo} />
        </div>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
}

export default Profile;