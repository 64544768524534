import { useState } from 'react';
import Player from "./Player";
import { config } from "../../config";
import { webrtcPlayUrl } from "../../utils";
import { WindowOutlined, GridOnOutlined, SpaceDashboardOutlined, CalendarViewMonthOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import "./Videos.css";

/**
 * A component that displays multiple video streams in a grid layout.
 * @param {string[]} props.appName - The app name for param `?app=`, which is a unique identifier for each company's streams,
 * computed by hashing user's company name
 */
const Videos = ({ appName }) => {
  const DEFAULT_NUMBER_OF_VIDEOS = 4;
  const [layout, setLayout] = useState("two-by-two");

  const src = Array.from({ length: DEFAULT_NUMBER_OF_VIDEOS }).map((_, i) => {
    return webrtcPlayUrl(appName, config.STREAM_NAME + (i + 1).toString());
  });
  const [source, setSource] = useState(src);

  /**
   * Creates an array of HLS video stream URLs with the given number of elements.
   * If the number is greater than the length of the original source array,
   * the first n elements of the source array will be appended to the end of the new array,
   * where n = num - src.length.
   * @param {number} num - The number of elements in the new array.
   * @returns {string[]} - An array of HLS video stream URLs.
   * @throws {Error} - If num is less than or equal to 0.
   */
  const createSource = (num) => {
    if (num <= 0) {
      throw new Error("num must be greater than 0");
    }
    else if (num <= source.length) {
      return source.slice(0, num);
    }
    else {
      return [...source, ...createSource(num - source.length)];
    }
  }

  /**
   * Handles a click event on a video player item.
   * If the item has an id of "5", swaps the current video source with the first one.
   * Otherwise, changes the current video source to the selected one.
   * @param {number} index - The index of the clicked video player item.
   * @param {Object} args - Additional arguments passed by the event handler. See `onclick` props in Player.js.
   * @param {string} args.id - The id of the clicked video player item.
   */
  const handleItemClick = (index, { id }) => {
    if (id === "5") {
      setSource(prevSource => {
        const newSource = [...prevSource];
        const current = newSource[index];
        newSource[index] = newSource[0];
        newSource[0] = current;
        return newSource;
      });
    }
    else {
      setSource(prevSource => {
        const newUrl = webrtcPlayUrl(appName, config.STREAM_NAME + id);
        const newSource = [...prevSource];
        newSource[index] = newUrl;
        return newSource;
      });
    }
  }

  const videos = source.map((url, index) => {
    return (
      <Player
        className="player"
        url={url}
        key={index}
        menuId={`HLS_CHANGE_${index}`}
        layout={layout}
        handleItemClick={(...args) => handleItemClick(index, ...args)}
      />
    )
  })

  const layouts = [
    { icon: <WindowOutlined />, name: 'two-by-two', videoNum: 4 },
    { icon: <SpaceDashboardOutlined />, name: 'left-one-right-three', videoNum: 4 },
    { icon: <CalendarViewMonthOutlined />, name: 'top-two-bottom-four', videoNum: 6 },
    { icon: <GridOnOutlined />, name: 'three-by-three', videoNum: 9 }
  ];

  const buttons = layouts.map(({ icon, name, videoNum }) => (
    <IconButton 
      key={name}
      onClick={() => {
        setLayout(name);
        setSource(createSource(videoNum));
      }}
      style={{ color: 'white', backgroundColor: '#010409' }}
    >
      {icon}
    </IconButton>
  ));

  return (
    <div className='videos'>
      <div className='buttons'>
        {buttons}
      </div>
      <div className={`video-grid ${layout}`}>
        {videos}
      </div>
    </div>
  )
}

export default Videos